
import { CalculatePrice, FormatDate, ThousandSeparator } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import SecurePaymentInfo from "@/components/checkout-process/SecurePaymentInfo.vue";
import OrderSummaryProductTile from '@/components/checkout-process/checkout/OrderSummaryProductTile.vue'
import { CurrencyViewModel, OrderCartDeliveryOptionViewModel, VoucherApplyToType } from "@/api-client";

@Options({
  components: { SecurePaymentInfo, OrderSummaryProductTile },
  props: {
    cart: { default: null },
    currency: { default: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      }
    },
    deliveryDetails: { default: null },
    totalPrice: { default: 0 },
    isAnonStoreCheckout: { default: false }
  },
})
export default class OrderSummary extends mixins(CalculatePrice, ThousandSeparator, FormatDate) {
  cart: any = null;
  isAnonStoreCheckout: any = false;
  deliveryDetails: Array<OrderCartDeliveryOptionViewModel> = [];
  totalPrice = 0;
  currency: CurrencyViewModel = {
    id: "",
    name: "",
    code: "USD",
    symbol: "",
    pricingMultiplier: 0,
    decimalPlaces: 2,
  }
  voucherApplyTypes: any = {
    [VoucherApplyToType.TotalCost.toString()]: 'this promo code will apply to the total cart value',
    [VoucherApplyToType.ProductCost.toString()]: 'this promo code will apply to a product within the cart',
    [VoucherApplyToType.DeliveryCost.toString()]: 'this promo code will apply to the order delivery cost'
  }

  get totalOrderPrice() {
    if(this.deliveryDetails.length) {
      let subtotal = this.calculatePrice(this.totalPrice + this.deliveryDetails[0].deliveryCost, this.currency.pricingMultiplier);
      const total = Number(subtotal) + this.cart.additionalCostsConvertedTotal - Number(this.voucherFinalValue) - Number(this.cart.paymentsConvertedTotal);
      if(total > 0) {
        return total.toFixed(2);
      }
    }
    return 0;
  }

  get subTotalOrderPrice() {
    const subtotal = this.calculatePrice(this.totalPrice, this.currency.pricingMultiplier)
    const total = Number(subtotal) + this.cart.additionalCostsConvertedTotal;
    return total.toFixed(2);
  }

  get voucherFinalValue() {
    if (this.cart.voucher && (this.cartVoucherCriteria.includes('voucherValid'))) {
      if (this.cart.voucher.productVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.productVoucherAmount, 1)).toFixed(2);
      }
      else if (this.cart.voucher.deliveryVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.deliveryVoucherAmount, 1)).toFixed(2);
      }
      else if (this.cart.voucher.totalVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.totalVoucherAmount, 1)).toFixed(2);
      }
      return 0;
    }
    return 0;
  }

  get cartVoucherCriteria() {
    if(this.cart.voucher) {
      let criteria: Array<any> = [];
      if(this.cart.voucher.minimumCartValue && this.subTotalOrderPrice) {
        const valueReached = (+this.subTotalOrderPrice - +this.calculatePrice(this.cart.voucher.minimumCartValue, this.currency.pricingMultiplier)) >= 0;

        if (valueReached) {
          criteria.push('cartValueValid')
        } else {
          criteria.push('cartValueNotValid')
        }
      }

      if(this.cart.voucher.startDate && this.cart.voucher.endDate) {
        const now = new Date();
        const start = new Date(this.cart.voucher.startDate);
        const end = new Date(this.cart.voucher.endDate);

        if (now > start && now < end) {
          criteria.push('cartDateValid')
        } else {
          criteria.push('cartDateNotValid')
        }
      }

      if(!criteria.includes('cartValueNotValid') && !criteria.includes('cartDateNotValid') ){
        criteria.push('voucherValid')
      }

      return criteria
    }
    return ['noVoucherApplied']
  }

  get cartVoucherSuggestions() {
    if(this.cart.voucher) {
      let datePending = null;
      let dateExpired = null;

      const voucherConvertedValue = this.cart.voucher.voucherTypeId === 'Absolute'
        ? `${this.currency.symbol}${this.calculatePrice(this.cart.voucher.value, this.currency.pricingMultiplier)}`
        : `${this.cart.voucher.value * 100}%`;

      const outstandingValueConverted = this.cart.voucher.minimumCartValue
        ? `${this.currency.symbol}${(+this.calculatePrice(this.cart.voucher.minimumCartValue, this.currency.pricingMultiplier) - +this.subTotalOrderPrice).toFixed(2)} `
        : null;

      if(this.cartVoucherCriteria.includes('cartDateNotValid') && (this.cart.voucher.startDate && this.cart.voucher.endDate)) {
        const now = new Date();
        const start = new Date(this.cart.voucher.startDate);
        const end = new Date(this.cart.voucher.endDate);

        datePending = now < start;
        dateExpired = now > end;
      }

      return {
        cartValueNotValid:
          this.cartVoucherCriteria.includes('cartValueNotValid')
            ? `Add ${outstandingValueConverted} for ${voucherConvertedValue} off!`
            : null,

        cartDateNotValid:
          this.cartVoucherCriteria.includes('cartDateNotValid') && dateExpired
            ? `Promo Code expired ${this.formatDate(this.cart.voucher.endDate)}`
            : this.cartVoucherCriteria.includes('cartDateNotValid') && datePending
            ? `Promo Code valid</br>${this.formatDate(this.cart.voucher.startDate)} - ${this.formatDate(this.cart.voucher.endDate)}`
            : null,

        cartDateValid:
          this.cartVoucherCriteria.includes('cartDateValid')
            ? `Promo Code valid</br>${this.formatDate(this.cart.voucher.startDate)} - ${this.formatDate(this.cart.voucher.endDate)}`
            : null
      }
    }
    return ({
      cartValueNotValid: null,
      cartDateNotValid: null,
      cartDateValid: null
    })
  }

  determineDelivery() {
    if(this.deliveryDetails.length) {
      let deliveryType = this.deliveryDetails[0]
      let date = new Date();
      let deliveryDays = deliveryType.deliveryDays;
      let totalDays = deliveryDays + deliveryType.productionDays;

      if(totalDays > 0) {
        date.setDate(date.getDate() + totalDays);
        return this.formatDate(date) ;
      } else {
        return 'Contact us'
      }
    }     
  }
}
